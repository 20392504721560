import {__} from '@wordpress/i18n';
import {makeClass} from '../../assets/js/utils/make-selector';
import Litepicker from 'Litepicker';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import moment from 'moment';
import { Member } from '../../assets/js/utils/travelanium';

const {
  defaultReservationStart,
  defaultReservationEnd,
  defaultPromotionCode,
} = therock.reservation;

export function init( block ) {
  let bookingForm = block.querySelector('form');
  let today = new Date().setHours(0,0,0,0);

  let adp = new Litepicker({
    element: bookingForm.querySelector('#reservation_date'),
    minDate: today,
    minDays: 2,
    singleMode: false,
    firstDay: 0,
    lang: document.documentElement.lang.substring(0, 2) || 'en',
    buttonText: {
      previousMonth: '<i class="fas fa-arrow-circle-left"></i>',
      nextMonth: '<i class="fas fa-arrow-circle-right"></i>',
    },
    format: 'DD MMM YYYY',
    tooltipNumber: (n) => {
      return n-1;
    },
    tooltipText: {
      one: __('night', 'therock'),
      other: __('nights', 'therock'),
    },
    setup: picker => {
      picker.on('selected', (a, b) => {
        let cid = a.dateInstance;
        let cod = b.dateInstance;
        let fci = bookingForm.querySelector('[name=checkin]');
        let fco = bookingForm.querySelector('[name=checkout]');
        fci.value = cid ? moment(cid).format('YYYY-MM-DD') : '';
        fco.value = cod ? moment(cod).format('YYYY-MM-DD') : '';
      });
    }
  });

  let cis = moment().add(defaultReservationStart, 'd').toDate();
  let cos = moment().add(defaultReservationStart + defaultReservationEnd, 'd').toDate();
  adp.setDateRange(cis, cos);

  function bookingFormAlert( html ) {
    return Fancybox.show([
      {
        src: html,
        type: 'html',
      }
    ], {
      mainClass: 'booking-form-alert',
      on: {
        closing() {
          adp.show()
        },
      }
    });
  }

  bookingForm.addEventListener('submit', e => {
    e.preventDefault();

    let form = e.target;
    let data = new FormData(form);
    let errors = [];

    if (!data.get('checkin') || !data.get('checkout')) {
      errors.push( __('Please select reservation date.', 'therock') );
    }

    if (errors.length) {
      let html = '<div class="content"><ul>';
      errors.forEach(err => html += `<li>${err}</li>`);
      html += '</ul></div>';
      bookingFormAlert(html);
      return;
    }

    if (!data.get('accesscode') && Member.isMember()) {
      data.set('accesscode', therock.member.code);
    }

    if (!data.get('accesscode') && defaultPromotionCode) {
      data.set('accesscode', defaultPromotionCode);
    }

    let action = form.getAttribute('action');
    let target = form.getAttribute('target') || '_blank';
    let params = '';
    let count = 0;

    for(let pair of data.entries()) {
      let conj = count !== 0 ? '&' : '';
      params += `${conj}${pair[0]}=${pair[1]}`;
      count++;
    }

    window.open( action + '?' + params, target );
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-booking-bar');
  blocks.forEach(init);
}

export function editor() {
  wp.hooks.addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.blocks', function ( props ) {
    let {attributes, block} = props;
    if ( 'lazyblock/booking-bar' === block ) {
      init( document.querySelector(makeClass(attributes.blockUniqueClass)) );
    }
  } );
}

export default {
  frontend,
  editor
}