import Isotope from 'isotope-layout';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

function init( block ) {
  let isMasonry = block.classList.contains('block-cards--masonry');
  
  if (isMasonry) {
    let masonryContainer = block.querySelector('.block-cards-inner');
    new Isotope(masonryContainer, {
      itemSelector: '.card-item',
      layoutMode: 'masonry',
    });
  }
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-cards');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.blocks', function ( props ) {
    let {blockUniqueClass} = props.attributes;
    if ('lazyblock/cards' === props.block) {
      let container = document.querySelector( makeClass(blockUniqueClass) );
      let block = container.querySelector('.block-cards');
      init( block );
    }
  } );
}

export default {
  frontend,
  editor,
}