import $ from 'jquery';
import Swiper from 'swiper';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

function init( block ) {
  let swiperEl = block.querySelector('.swiper-container');
  let settings = {}

  try {
    settings = JSON.parse( swiperEl.dataset.settings );
  } catch (e) {
    console.error(e);
  }

  if (swiperEl) {
    new Swiper(swiperEl, {
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      grabCursor: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
    });

    if (settings.lightbox) {
      let images = Array.prototype.map.call( swiperEl.querySelectorAll('.image'), image => {
        let src     = image.dataset.lightboxSrc;
        let width   = parseInt( image.dataset.lightboxWidth );
        let height  = parseInt( image.dataset.lightboxHeight );
        let thumb   = image.querySelector('img');
    
        return {
          src: src,
          width: width,
          height: height,
          $thumb: $(thumb),
          thumb: thumb ? thumb.src : null,
        }
      } );

      $(swiperEl).on('click', '.image', el => {
        el.preventDefault();
        let img   = el.currentTarget;
        let index = img.dataset.lightboxIndex;
        jQuery.fancybox.open(images, {}, index);
      });
    }
  }
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-image-carousel');
  blocks.forEach(block => init(block));
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.blocks', function ( props ) {
    let {attributes} = props;
    if ('lazyblock/image-carousel' === props.block) {
      let block = document.querySelector( makeClass(attributes.blockUniqueClass) );
      init( block );
    }
  } );
}

export default {
  frontend,
  editor,
}