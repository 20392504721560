import jarallax from 'jarallax';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

function init( block ) {
  let jarallaxEl = block.querySelectorAll('.jarallax');
  jarallax( jarallaxEl );
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-deal-query');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;
    if ('lazyblock/deal-query' === block) {
      let block = document.querySelector( makeClass(attributes.blockUniqueClass) );
      init( block );
    }
} );
}

export default {
  frontend,
  editor
}
