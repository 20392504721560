import Swiper from 'swiper';
import {makeClass} from '../../assets/js/utils/make-selector';
import {addAction} from '@wordpress/hooks';
import 'nodelist-foreach-polyfill';

function init( block ) {
  let itemEl = block.querySelectorAll('.block-room-query__item');
  itemEl.forEach( item => {
    let swiperEl = item.querySelector('.swiper-container');

    if (swiperEl) {
      let paginationEl = swiperEl.querySelector('.swiper-pagination');
  
      new Swiper( swiperEl, {
        pagination: {
          el: paginationEl,
        }
      } );
    }
  } );
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-room-query');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;

    if ( 'lazyblock/room-query' === block ) {
      let block = document.querySelector( makeClass( attributes.blockUniqueClass ) );
      init( block );
    }
} );
}

export default {
  frontend,
  editor
}
