/**
 * Make string become to Class selector
 *
 * @param {string} string 
 * @returns Class selector
 */
 export function makeClass( string ) {
  return `.${string}`;
}

/**
 * Make string become to ID selector
 *
 * @param {string} string 
 * @returns ID selector
 */
export function makeId( string ) {
  return `.${string}`;
}

export default {
  makeClass,
  makeId,
}