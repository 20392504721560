import Swiper from 'swiper';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

/**
 * Init
 * @param {HTMLElement} block 
 */
function init( block ) {
  let tabs = [...block.querySelectorAll('[data-bs-toggle=tab]')];
  let swiperEl = block.querySelector('.swiper-container');

  let hasActive = tabs.find(tab => tab.classList.contains('active'));
  if (!hasActive) {
    let firstTab = $tabs[0]
    if (firstTab) {
      new bootstrap.Tab(firstTab).show();
    }
  }
  
  if (swiperEl) {
    let main = block.querySelector('.slide-main');
    let thumb = block.querySelector('.slide-thumb');

    if (main && thumb) {
      let thumbSwiper = new Swiper(thumb, {
        slidesPerView: 4,
        spaceBetween: 10,
        freeMode: true,
        slideToClickedSlide: true,
        breakpoints: {
          576: {
            slidesPerView: 6,
          },
          768: {
            slidesPerView: 10,
          },
        },
      } );
  
      new Swiper( main, {
        spaceBetween: 10,
        grabCursor: true,
        breakpoints: {
          576: {
            spaceBetween: 25,
          },
        },
        thumbs: {
          swiper: thumbSwiper,
        }
      } );
    }

    let plan = block.querySelector('.slide-plans');

    if (plan) {
      let planImages = [...plan.querySelectorAll('img')];
      let planArr = planImages.map(image => {
        let largeSrc = image.parentElement.dataset.largeSrc;
        return {
          $thumb: image,
          thumb: image.src,
          src: largeSrc,
          type: 'image',
        }
      });

      new Swiper(plan, {
        slidesPerView: 'auto',
        spaceBetween: 10,
        slideToClickedSlide: true,
      });

      plan.addEventListener('click', ev => {
        let target = ev.target.closest('img');
        let parent = target.closest('.swiper-slide');
        let i = [...parent.parentElement.children].findIndex(e => e === parent);

        Fancybox.show(planArr, {
          startIndex: i,
        });
      })
    }
  }

  Array.prototype.forEach.call(tabs, tab => {
    tab.addEventListener('shown.bs.tab', ev => {
      let contentEl = document.querySelector(ev.target.dataset.bsTarget);
      let swiperEl = contentEl.querySelectorAll('.swiper-container');
      swiperEl.forEach(swiper => swiper.swiper.update());
    });
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-room-media');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;

    if ( 'lazyblock/room-media' === block ) {
      let block = document.querySelector( makeClass( attributes.blockUniqueClass ) );
      init( block );
    }
} );
}

export default {
  frontend,
  editor,
}