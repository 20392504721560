import 'nodelist-foreach-polyfill';

/**
 * Blocks
 */
 import blockBookingBar from '../../blocks/lazyblock-booking-bar/block';
 import blockCards from '../../blocks/lazyblock-cards/block';
 import blockGallery from '../../blocks/lazyblock-gallery/block';
 import blockImageCarousel from '../../blocks/lazyblock-image-carousel/block';
 import blockRoomCarousel from '../../blocks/lazyblock-room-carousel/block';
 import blockRoomRelation from '../../blocks/lazyblock-room-relation/block';
 import blockSingleImageCarousel from '../../blocks/lazyblock-single-image-carousel/block';
 import blockRoomMedia from '../../blocks/lazyblock-room-media/block';
 import blockRoomQuery from '../../blocks/lazyblock-room-query/block';
 import blockDealQuery from '../../blocks/lazyblock-deal-query/block';
 import blockReviewQuery from '../../blocks/lazyblock-review-query/block';
 
 blockBookingBar.frontend();
 blockCards.frontend();
 blockGallery.frontend();
 blockImageCarousel.frontend();
 blockRoomCarousel.frontend();
 blockRoomRelation.frontend();
 blockSingleImageCarousel.frontend();
 blockRoomMedia.frontend();
 blockRoomQuery.frontend();
 blockDealQuery.frontend();
 blockReviewQuery.frontend();