import Swiper from 'swiper';
import {makeClass} from '../../assets/js/utils/make-selector';

function init( block ) {
  let swiperEl = block.querySelector('.swiper-container');
  let swiperButtonPrev = block.querySelector('.block-room-relation__button-prev');
  let swiperButtonNext = block.querySelector('.block-room-relation__button-next');
  
  new Swiper(swiperEl, {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      prevEl: swiperButtonPrev,
      nextEl: swiperButtonNext,
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      }
    }
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-room-relation');
  blocks.forEach(init);
}

export function editor() {
  wp.hooks.addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;
    if ('lazyblock/room-relation' === block) {
      let block = document.querySelector( makeClass(attributes.blockUniqueClass) );
      init(block);
    }
} );
}

export default {
  frontend,
  editor
}