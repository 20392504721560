import $ from 'jquery';
import Isotope from 'isotope-layout';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import {makeClass} from '../../assets/js/utils/make-selector';

function init( block ) {
  let $block = $(block);
  let $tabs = $block.find('.gallery-tabs');
  let $tabEl = $tabs.find('[data-bs-toggle]');
  let $contents = $block.find('.gallery-contents');

  $tabs.on('mousewheel', ev => {
    ev.preventDefault();
    let {deltaY} = ev.originalEvent;
    $tabs.get(0).scrollBy({
      behavior: 'auto',
      left: deltaY,
    });
  });

  $tabs.on('click', '[data-bs-toggle]', ev => {
    let target = ev.currentTarget;
    let wrapper = target.closest('.nav');
    let wrapperWidth = wrapper.clientWidth;
    let item = target.parentElement;
    let itemPos = item.offsetLeft;
    let itemWidth = item.clientWidth;

    wrapper.scrollTo({
      behavior: 'smooth',
      left: itemPos - wrapperWidth / 2 + itemWidth / 2,
    });
  });

  let imageRow = block.querySelectorAll('.image-row');

  imageRow.forEach(el => {
    new Isotope(el, {
      layoutMode: 'masonry',
      stagger: 15,
    });
  });

  $contents.on('click', '[data-large-src]', ev => {
    ev.preventDefault();
    let target = ev.currentTarget;
    let index = $(target).index();
    let parent = target.closest('.tab-pane');
    let type = target.dataset.largeType;

    let $gallery = $(parent).find('.image-item');
    let lightbox = $gallery.map((i, el) => {
      let img = el.querySelector('img');
      return {
        src: el.dataset.largeSrc,
        type: type,
        thumb: img.src,
        caption: el.dataset.caption,
        $thumb: img,
      }
    });

    Fancybox.show(lightbox, {
      startIndex: index,
    });
  });

  $tabEl.each((i, el) => {
    el.addEventListener('shown.bs.tab', ev => {
      let masonry = block.querySelector( ev.target.dataset.bsTarget ).querySelector('.image-row');
      let inst = Isotope.data(masonry);
      if (inst) {
        inst.arrange('layout');
      }
    });
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-gallery');
  blocks.forEach( init );
}

export function editor() {
  wp.hooks.addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;
    if ( 'lazyblock/gallery' === block ) {
      let block = document.querySelector(makeClass(attributes.blockUniqueClass));
      init( block );
    }
  } );
}

export default {
  frontend,
  editor
}