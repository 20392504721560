import Swiper from 'swiper';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

function getAutoplay( settings ) {
  let def = false;
  if (settings.autoplay) {
    def = {
      autoplay: true,
    }
  }
  return def;
}

function getLoop( settings ) {
  let def = false;
  if (settings.loop) {
    def = {
      loop: true,
    }
  }
  return def;
}

function getEffect( settings ) {
  if (settings.effect) {
    switch (settings.effect) {
      case 'fade':
        return {
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
        }
      default:
        return {
          effect: 'slide',
        }
    }
  }
}

function getNavitaion( settings ) {
  let def = false;
  if (settings.navigation) {
    def = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    }
  }
  return def;
}

function getPagination( settings ) {
  if (settings.pagination) {
    let style = settings.paginationStyle || 'dots';
    switch (style) {
      case 'dots':
        return {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true,
          }
        }
      case 'number':
        return {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            bulletClass: 'swiper-pagination-bullet swiper-bullet-number',
            renderBullet(index, className) {
              return `<span class="${className}">${index + 1}</span>`;
            }
          },
        }
    }
  }
  return false;
}

/**
 * Init
 * @param {HTMLElement} block 
 */
function init( block ) {
  console.log(block);
  let swiperEl = block.querySelector('.swiper-container');

  console.log(swiperEl);
  let settings = {};

  try {
    // settings = JSON.parse(block.querySelector('.settings').innerText);
    settings = JSON.parse(block.dataset.settings);
  } catch (e) {
    console.error(e);
  }

  let autoplaySetting = getAutoplay(settings);
  let loopSetting = getLoop(settings);
  let effectSetting = getEffect(settings);
  let navigationSetting = getNavitaion(settings);
  let paginationSetting = getPagination(settings);
  let spaceBetweenSetting = settings.spaceBetween || 0;

  new Swiper( swiperEl, {
    slidesPerView: 1,
    spaceBetween: spaceBetweenSetting,
    grabCursor: true,
    on: {
      init(inst) {
        if (settings.pagination && settings.paginationStyle) {
          inst.el.classList.add(`single-image-carousel--${settings.paginationStyle}`);
        }
      }
    },
    ...autoplaySetting,
    ...loopSetting,
    ...navigationSetting,
    ...paginationSetting,
    ...effectSetting,
  } );
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-single-image-carousel');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.block', function ( props ) {
    let {attributes, block} = props;
    console.log(props);
    if ('lazyblock/single-image-carousel' === block) {
      init( document.querySelector( makeClass(attributes.blockUniqueClass) ) );
    }
} );
}

export default {
  frontend,
  editor
}