import anime from 'animejs';
import {makeClass} from '../../assets/js/utils/make-selector';
import {addAction} from '@wordpress/hooks';

function init( block ) {
  let $contents = block.querySelectorAll('.review-card__content');

  $contents.forEach(el => {
    let h  = el.scrollHeight;
    let lh = parseFloat( getComputedStyle(el).getPropertyValue('line-height') );
    let mh = lh * 4;

    el.style.minHeight = `${mh}px`;
    el.style.maxHeight = `${mh}px`;
    el.style.overflow = 'hidden';
    el.style.willChange = 'max-height, height, min-height';
    
    if (h > mh) {
      el.classList.add('collapsible');
      el.classList.add('collapsed');
    }

    let a;

    el.collapse = {
      show() {
        let h = el.scrollHeight;
        el.classList.remove('collapsed');
        a = anime({
          targets: el,
          maxHeight: [mh, h],
          duration: 500,
          easing: 'easeOutExpo',
        });
      },
      hide() {
        a.reset();
        el.style.maxHeight = mh;
        el.classList.add('collapsed');
      },
      isShow() {
        return !el.classList.contains('collapsed');
      }
    }
  });

  document.body.addEventListener('click', ev => {
    let target = ev.target;
    if (target.closest('.collapsible')) {
      let el = target.closest('.collapsible');
      if (el.collapse.isShow()) {
        el.collapse.hide();
      } else {
        el.collapse.show();
      }
    }
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-review-query');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.blocks', function ( props ) {
    let {attributes} = props;
    if ('lazyblock/review-query' === props.block) {
      let block = document.querySelector( makeClass(attributes.blockUniqueClass) );
      init( block );
    }
  } );
}

export default {
  frontend,
  editor,
}