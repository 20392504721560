import Swiper from "swiper";
import SVGInject from '@iconfu/svg-inject';
import {addAction} from '@wordpress/hooks';
import {makeClass} from '../../assets/js/utils/make-selector';

/**
 * 
 * @param {HTMLElement} block 
 */
function init( block ) {
  let swiperEl = block.querySelector('.swiper-container');
  let nextEl = block.querySelector('.carousel-button-next');
  let prevEl = block.querySelector('.carousel-button-prev');

  let svgImageEl = block.querySelectorAll('img.inline-svg[src$=svg]');
  SVGInject(svgImageEl);

  new Swiper(swiperEl, {
    speed: 800,
    grabCursor: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },
    centeredSlides: true,
    spaceBetween: 60,
  });
}

export function frontend() {
  let blocks = document.querySelectorAll('.block-room-carousel');
  blocks.forEach(init);
}

export function editor() {
  addAction( 'lzb.components.PreviewServerCallback.onChange', 'therock.blocks', function ( props ) {
    let {blockUniqueClass} = props.attributes;
    if ('lazyblock/room-carousel' === props.block) {
      let container = document.querySelector( makeClass(blockUniqueClass) );
      let block = container.querySelector('.block-room-carousel');
      init( block );
    }
  } );
}

export default {
  frontend,
  editor
}